<template>
  <v-row>
    <v-col cols="12">
      <div class="pagination">
        <md-button
          class="md-raised back-btn"
          :disabled="hasPrevious"
          @click="previous()"
        >
          <v-icon class="paginateIcon">chevron_left</v-icon>
        </md-button
        >
        <md-button
          v-for="page in pageList"
          class="md-raised pagination-button"
          :md-ripple="false"
          :class="getPageClass(page)"
          :key="'page-' + page"
          @click="selectPage(page)"
        >{{ page }}
        </md-button
        >
        <md-button
          class="md-raised back-btn"
          :disabled="hasNext"
          @click="next()"
        >
          <v-icon class="paginateIcon">chevron_right</v-icon>
        </md-button
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>

//import bus from '../components/Inquiry/bus';
export default {
  name: "Pagination",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    perPageCount: {
      type: Number,
      default: 25,
    },
    maxVisiblePages: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.perPageCount);
    },
    hasPrevious() {
      return !this.currentPage || this.currentPage <= 1;
    },
    hasNext() {
      return !this.currentPage || this.currentPage >= this.totalPages;
    },
    pageList() {
      const pageSet = [];
      if (this.totalPages <= this.maxVisiblePages) {
        for (let i = 1; i <= this.totalPages; i++) {
          pageSet.push(i);
        }
        return pageSet;
      }

      let pagerStartPoint = this.getPagerStartPoint();
      if (this.currentPage <= this.maxVisiblePages) {
        for (let i = 1; i <= this.maxVisiblePages; i++) {
          pageSet.push(i);
        }
        return pageSet;
      }

      if (pagerStartPoint + this.maxVisiblePages >= this.totalPages) {
        pagerStartPoint = this.totalPages - this.maxVisiblePages + 1;
        for (let i = pagerStartPoint; i <= this.totalPages; i++) {
          pageSet.push(i);
        }
        return pageSet;
      }

      const pagerLastPoint = pagerStartPoint + this.maxVisiblePages;
      for (let i = pagerStartPoint; i < pagerLastPoint; i++) {
        pageSet.push(i);
      }

      return pageSet;
    },
  },


  methods: {
    getPagerStartPoint() {
      const lessValue = Math.floor(
        (this.currentPage - 1) / this.maxVisiblePages
      );
      return lessValue * this.maxVisiblePages + 1;
    },
    getPageClass(page) {
      if (page === this.currentPage) {
        return "md-primary";
      }

      return "";
    },
    selectPage(page) {
      if (page === this.currentPage) {
        return;
      }

      this.$emit("load-page", page);
    },
    next() {
      this.$emit("load-page", this.currentPage + 1);
    },
    previous() {
      this.$emit("load-page", this.currentPage - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
  .pagination {
    float: right;
  }
  .pagination-button {
    min-width: 35px;
    height: 32px;
  }
  .md-button.md-raised:not([disabled]).pagination-button {
    color: $primaryDarkest;
    background-color: $paginateBackground !important;
    border: 0.5px solid $primaryDarkest;
    border-radius: 3px;
    height: 30px;
    box-shadow: none !important;
  }
  .back-btn {
    color: $primaryDarkest !important;
    background-color: $paginateBackground !important;
    border: 0.5px solid $primaryDarkest !important;
    border-radius: 3px;
    height: 30px;
    min-width: 35px;
    box-shadow: none !important;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-primary{
    color: $paginateText;
    background-color: $primaryDarkest !important;
    border: 0.5px solid $primaryDarkest !important;
    border-radius: 3px;
    height: 30px;
  }

  .paginateIcon {
    color: $primaryDarkest;
  }
</style>
