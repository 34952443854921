<template>
  <div>
    <v-row justify="center" no-gutters>
      <div class="md-layout-item" @click="openInquiryView(inquiry.id)" :style="inquiryShow ? 'cursor: pointer;' : null">
        <div :class="`md-layout ${inquiryShow ? 'inquiry-container' : 'inquiry-container-not-navigable'}`" @click="inquiryClicked(inquiry.id)">
          <div style="padding: 0px 10px">
            <div class="embed">
              <embed
                class="centered-and-cropped"
                :src="
                    inquiry.default_image &&
                    isSupportedFileType(
                      inquiry.default_image
                    )
                      ? `${baseUrl}/api/inquiries/file_show/${inquiry.default_image}`
                      : sampleImage
                  "
                alt="sample image"
              />
            </div>
          </div>
          <v-divider vertical class="vertical-hr" />
          <div class="md-layout-item inquiry-data-container">
            <v-row :class="addButtonShow ? 'mb-2' : 'mt-1 mb-1'" style="margin-left: 0px;">
              <div class="collection-number" v-if="addButtonShow">{{ inquiry.collection_number }}</div>
              <div class="style-content">{{ inquiry.style_number }}</div>
            </v-row>

            <truncate-hovering
              class="truncate-hovering"
              :text="inquiry.style_name"
              :length="10"
              className="inquiry-data"
            />
            <div class="inquiry-data">Qty : {{ inquiry.bulk_order_quantity }}</div>
            <div class="inquiry-data">Colors : {{ inquiry.colour_number }}</div>
            <div class="inquiry-data">
              Product Type : {{ inquiry.product_type.name }}
            </div>
          </div>
        </div>
        <div class="action-button-container" v-if="addButtonShow">
          <md-button
            @click="moveInquiry(inquiry.id)"
          >
            <!-- <v-icon>mdi-folder-arrow-right-outline</v-icon> -->
            <v-icon>mdi-chevron-right</v-icon>
          </md-button>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import restAdapter from "../../restAdapter";
import * as conf from "../../conf.yml";
import utils from "../../utils";
import notification from "../../notification";

export default {
  name: "MiniInquiry",
  data: () => ({
    sampleImage: require(`../../assets/shirt.png`),
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
    baseUrl:null,
    collectionId: null,
  }),

  props: {
    inquiry: {
      type: [Object, null],
    },
    currentCollectionNumber: {
      type: String,
      default: "",
    },
    addButtonShow: {
      type: Boolean,
      default: false,
    },
    inquiryShow: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.getCollectionNumber();
  },

  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },

  methods:{
    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) && filename.match(/\.[0-9a-z]+$/i)[0].toLowerCase();
      const supportedFileTypes = this.acceptedThumbnailTypes.split(",");
      return supportedFileTypes.includes(extension);
    },
    inquiryClicked(id){
      if (!this.addButtonShow) {
        this.$emit('inquiry-clicked',id);
      }
    },

    openInquiryView(id){
      if(this.inquiryShow){
        this.$router.push({
          name: "Inquiry",
          params: { id: id },
        });
      }
    },

    moveInquiry(id){
      this.collectionId = this.$route.params.id;
      if (confirm("Do you want to move " + this.inquiry.style_number + " style from collection to "+ this.currentCollectionNumber +" collection? choose OK to move")) {
        restAdapter
          .post(`/api/collections/${this.collectionId}/add/${id}`)
          .then((response) => {
            notification.success("Style Moved to Collection Successfully");
            this.$emit('move');
          })
          .catch((error) => {
            notification.error("Something went wrong");
          });
      }
      else{
        notification.error(
          "Style was not moved."
        );
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.inquiry-container {
  height: 160px;
  justify-content: center;
  align-items: center;
  border: 1px solid $tableBorder;
  border-radius: 7px;
  background-color: #fff;
  box-sizing: border-box;
  transition-duration: 0.3s;
  margin: 10px;
}
.inquiry-container-not-navigable {
  height: 160px;
  justify-content: center;
  align-items: center;
  border: 1px solid $tableBorder;
  border-radius: 7px;
  background-color: #fff;
  box-sizing: border-box;
  transition-duration: 0.3s;
  margin: 10px;
}

.inquiry-container:not(:active):hover {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.14),
    0 1px 2px 1px rgba(0, 0, 0, 0.14), 0 1px 2px 1px rgba(0, 0, 0, 0.14),
    0 1px 2px 1px rgba(0, 0, 0, 0.14);
}

.inquiry-container:after {
  content: "";
  box-sizing: border-box;
  height: 150px;
  border-radius: 7px;
  opacity: 0;
  transition: all 0.4s;
}

.inquiry-container:active:after {
  background: rgba(0, 0, 0, 0.14);
  opacity: 0.5;
  transition: 0s;
}

.vertical-hr {
  align-self: center;
  border-color: $primaryDark !important;
  max-height: 75%;
  min-height: 75%;
}

.card-content-item {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.inquiry-data-container {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.inquiry-data {
  color: #473068;
}

.truncate-hovering::v-deep .inquiry-data {
  color: #473068;
}

.style-name-child-container{
  max-width: 150px !important;
}

.embed {
  width: 100px;
}

.centered-and-cropped {
  object-fit: cover;
  width: 100%;
}

.style-content {
  background-color: #473068;
  color: #fff;
  width: fit-content;
  font-size: 11px;
  padding: 3px 8px;
  margin-bottom: 2px;
  border-radius: 6px;
}
.collection-number {
  background-color: #70D0CE;
  color: #fff;
  width: fit-content;
  font-size: 11px;
  margin-right: 3px;
  padding: 3px 8px;
  margin-bottom: 2px;
  border-radius: 6px;
}

.action-button-container{
  text-align: right;
}

.md-button {
    min-width: 35px;
    height: 35px;
    border-radius: 32px;
    background-color: #70D0CE;
    margin: -22px -8px 0px 0px;
}
</style>
