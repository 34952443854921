<template>
  <v-container id="Collections" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-container class="py-0">
          <form novalidate @submit.prevent="getCollections" @md-changed="scrollToTop">
            <md-card-content class="search-content">
              <v-row class="collection-search-bar">
                <v-col xl="3" lg="3" xs="6">
                  <md-field class="search_field_class">
                    <label for="search-query">Search</label>
                    <md-input
                      v-on:input="debounceSearch"
                      name="search"
                      id="search"
                      v-model="query"
                    />
                  </md-field>
                </v-col>
                <v-col xl="3" lg="3" xs="6" v-if="!getIsSuperAdmin()">
                  <md-field class="search_field_class">
                    <label for="collection_type">Collection</label>
                    <md-select
                      @input="getCollections"
                      data-cy="collection-type"
                      name="collection-type"
                      id="collection-type"
                      v-model="collectionType"
                      placeholder="Collections"
                      :disabled="loading"
                    >
                      <div class="search_type_container">
                        <md-option class="list_color" value="all" v-if="!getIsCustomer()">All collections</md-option>
                        <md-option class="list_color" value="new" v-if="getIsAccountManager()">New collections</md-option>
                        <md-option class="list_color" value="own">Own collections</md-option>
                      </div>
                    </md-select>
                  </md-field>
                </v-col>
                <v-col lg="3" xs="6">
                  <v-btn
                    class="ma-2 mt-3 color_class"
                    dark
                    outlined
                    id="clear-filters"
                    :disabled="loading"
                    @click="clearFilters"
                  >
                    Clear filters
                  </v-btn>
                </v-col>
              </v-row>
            </md-card-content>
          </form>

          <div
            v-for="collection in collections"
            v-bind:key="collection.id"
          >
            <collection-list :collection="collection" />
          </div>
          <Pagination
            style="margin: -9px"
            :current-page="currentPage"
            :total-count="totalCount"
            @load-page="loadPage"
            v-if="collections.length"
          />
          <div v-if="loading" class="my-5">
            <md-progress-spinner
              class="color_stroke"
              md-mode="indeterminate"
            ></md-progress-spinner>
          </div>
          <v-row v-if="!loading && collections.length === 0">
            <v-col cols="12"> No Collections found. </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

/* eslint-disable @typescript-eslint/camelcase */
//import bus from '../collection/bus';
import restAdapter from "../../restAdapter";
import auth from "../../auth";
import Pagination from "../../components/Pagination";
import * as conf from "../../conf.yml";
import debounce from "lodash/debounce";
import utils from "../../utils";
import CollectionList from "../collection/CollectionList.vue";

export default {
  name: "CollectionListActive",
  filters: {
    truncate: function (text, stop, clamp) {
      return (
        text && text.slice(0, stop) + (stop < text.length ? clamp || "..." : "")
      );
    },
  },
  components: {
    Pagination,
    CollectionList,
  },
  data: () => ({
    loading: false,
    collectionType: "all",
    query: "",
    sampleImage: require(`../../assets/collection.png`),
    currentPage: 1,
    totalCount: 0,
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
    collections: [],
  }),

  mounted() {
    // this.loading = true;
    this.baseUrl = conf.default.server.host;
    this.collectionType = this.$route.query.collection_type ? this.$route.query.collection_type : "all";
    this.query = this.$route.query.query ? this.$route.query.query : " ";
    this.getCollections();
    this.scrollToTop();
  },

  methods: {
    scrollToTop(){
      window.scrollTo(0,0);
    },
    getIsAccountManager() {
      return auth.user.userType === "account_manager";
    },
    getIsSuperAdmin() {
      return auth.user.userType === "super_admin";
    },
    getIsSupplier() {
      return auth.user.userType === "supplier";
    },
    getIsCustomer() {
      return auth.user.userType === "customer";
    },
    getCollections() {
      this.loading = true;

      this.$router.push({
        query: {
          "collection_type": this.collectionType,
          "query": this.query,
        },
      });
      let url = "/api/collections?page=" + this.currentPage;
      url +=
        "&query=" +
        this.query +
        "&collection_type=" + this.collectionType ;

      restAdapter.get(url).then((response) => {
        this.collections = response.data.data;
        this.totalCount = response.data.meta.total;
        this.loading = false;
      });
    },

    loadPage(page) {
      this.currentPage = page;
      this.getCollections();
    },

    clearFilters() {
      this.collectionType = "all";
      this.query = "";

      this.$router.push({
        path: "collections",
        query: {
          "collection_type": this.collectionType,
          "query": this.query,
        },
      });
    },

    debounceSearch: debounce(function () {
      this.getCollections();
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.grid-2 {
  display: flex;
  flex-direction: row;
}

.collection-search-bar {
  text-align: right;
  // justify-content: flex-end;
  justify-content: left;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.search-content {
  margin-bottom: 40px;
  padding: 12px;
}

.search_field_class {
  margin: 0%;
}

.collection-style-number {
  color: #f3f1f1;
  text-align: left;
  font-family: sans-serif;
  font-weight: 500;
}

.collection-type {
  color: #f3f1f1;
}

.date {
  color: #f3f1f1;
  text-align: right;
}

.color_class {
  border: 1px solid #473068;
  color: #442d65 !important;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.md-field.md-theme-default:before {
  background-color: #473068 !important;
}

.list_color.md-selected::v-deep .md-list-item-text {
  color: #473068 !important;
}

.md-field.md-theme-default.md-focused label {
  color: #442d65 !important;
}

.search_type_container {
  margin-top: -10px !important;
  background-color: #f3edf7 !important;
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}

.list_color.md-theme-default.md-selected {
  background-color: #e2daed !important;
}
</style>
